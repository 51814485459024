import './App.css';
import React from 'react';
import { Button} from 'evergreen-ui'

function findData(letter) {
  let data = [
    {letter: 'A' , model: './assets/AtoZ/A/Astronaut.gltf', tag:'Astronaut'},
    {letter: 'B' , model: './assets/AtoZ/B/Basketball.gltf', tag:'BasketBall'},
    {letter: 'C' , model: './assets/AtoZ/C/Mesh_Cat.gltf', tag:'Cat'},
    {letter: 'D' , model: './assets/AtoZ/D/duck.gltf', tag:'Duck'},
    {letter: 'E' , model: './assets/AtoZ/E/Elephant.gltf', tag:'Elephant'},
    {letter: 'F' , model: './assets/AtoZ/F/NOVELO_FROG.gltf', tag:'Frog'},
    {letter: 'G' , model: './assets/AtoZ/G/Grapes_01.gltf', tag:'Grapes'},
    {letter: 'H' , model: './assets/AtoZ/H/Mesh_Hen.gltf', tag:'Hen'},
    {letter: 'I' , model: './assets/AtoZ/I/CHAHIN_POPSICLE.gltf', tag:'Ice Cream'},
    {letter: 'J' , model: './assets/AtoZ/J/Jar.gltf', tag:'Jar'},
    {letter: 'K' , model: './assets/AtoZ/K/Key_01(1).gltf', tag:'Keys'},
    {letter: 'L' , model: './assets/AtoZ/L/Lion.gltf', tag:'Lion'},
    {letter: 'M' , model: './assets/AtoZ/M/CairoSpinyMouse.gltf', tag:'Mouse'},
    {letter: 'N' , model: './assets/AtoZ/N/PUSHILIN_birds_nest.gltf', tag:'Nest'},
    {letter: 'O' , model: './assets/AtoZ/O/GreatHornedOwl.gltf', tag:'Owl'},
    {letter: 'P' , model: './assets/AtoZ/P/Pencil_01.gltf', tag:'Pencil'},
    {letter: 'Q' , model: './assets/AtoZ/Q/Queen.glb', tag:'Queen'},
    {letter: 'R' , model: './assets/AtoZ/R/Rabbit.glb', tag:'Rabbit'},
    {letter: 'S' , model: './assets/AtoZ/S/Snake.glb', tag:'Snake'},
    {letter: 'T' , model: './assets/AtoZ/T/tiger.glb', tag:'Tiger'},
    {letter: 'U' , model: './assets/AtoZ/U/Umbralla.glb', tag:'Umbrella'},
    {letter: 'V' , model: './assets/AtoZ/V/Van.glb', tag:'Van'},
    {letter: 'W' , model: './assets/AtoZ/W/Watch.glb', tag:'Watch'},
    {letter: 'X' , model: './assets/AtoZ/X/Xmas_tree.glb', tag:'Xmas tree'},
    {letter: 'Y' , model: './assets/AtoZ/Y/Yacht.glb', tag:'Yacht'},
    {letter: 'Z' , model: './assets/AtoZ/Z/zebra.glb', tag:'Zebra'}
  ]
  return(data[letter.charCodeAt(0)-65]);
}

class MainBook extends React.Component{
  constructor(props){
    super(props);
    this.state = { letter: 'A', model: './assets/AtoZ/A/Astronaut.gltf', tag:'Astronaut'};
    this.nextButton = this.nextButton.bind(this);
    this.preButton = this.preButton.bind(this);
  }
  nextButton() {
    let newLetter = String.fromCharCode((this.state.letter.charCodeAt(0)+1-65)%26 + 65);
    this.handleChange(newLetter);
  }
  preButton(){
    let newLetter = String.fromCharCode((this.state.letter.charCodeAt(0)-1-90)%26 + 90);
    this.handleChange(newLetter);
  }
  handleChange(letter){
    let s = findData(letter);
    this.setState(s);
  }
  render(){
    return(
      <div>
      <div class="test">
    <div class="object">
      <model-viewer src={this.state.model} alt="A 3D model of an astronaut" ar ar-modes="webxr scene-viewer quick-look" environment-image="neutral" auto-rotate camera-controls></model-viewer>
      <h2>{this.state.tag}</h2>
      </div>
      <div class="letter">
        <h1 class="lett">{this.state.letter}</h1>
        <Button onClick={this.preButton} size="large">PREVIOUS</Button>
        <Button float="right" onClick={this.nextButton} size="large">NEXT</Button>
        </div>
        
      </div>
      
      </div>
    );
  }

}

//export default App;
export default MainBook;
